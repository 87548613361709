import { Box, Flex, IconButton, Image, Switch, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import { ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { useDeleteCodeMutation } from 'services/game';

const CodeTable = ({ data, refetch }) => {
    const history = useHistory();
    const [sorting, setSorting] = useState([]);
    const columnHelper = createColumnHelper();

    const deleteMutation = useDeleteCodeMutation();

    const handleDelete = bookMaker => {
        const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa mã tín dụng này không?');
        if (!confirmDelete) {
            return;
        }

        deleteMutation.mutate(
            { id: bookMaker?._id },
            {
                onSuccess: () => {
                    toast.showMessageSuccess('Xóa mã tín dụng thành công');
                    refetch?.();
                },
                onError: () => {
                    toast.showMessageError('Xóa mã tín dụng thất bại');
                    refetch?.();
                },
            }
        );
    };
    const columns = useMemo(
        () => [
            columnHelper.accessor('code', {
                header: 'Mã',
                cell: info => <Box textTransform="uppercase">{info.renderValue()}</Box>,
            }),
            columnHelper.accessor('coin', {
                header: 'Xu',
                cell: info => <Box textTransform="uppercase">{info.renderValue()}</Box>,
            }),
            columnHelper.accessor('user', {
                header: 'Người tạo',
                cell: info => <Box>{info?.row?.original?.user?.username}</Box>,
            }),
            columnHelper.accessor('isUsed', {
                header: 'Trạng thái',
                cell: info => <Box textTransform="uppercase">{info.renderValue() === 1 ? "Đã dùng" : "Chưa sử dụng"}</Box>,
            }),
            columnHelper.accessor('updatedAt', {
                header: 'Ngày cập nhật',
                cell: info => formatDate(info.getValue()),
            }),
            columnHelper.accessor('action', {
                header: 'Thao tác',
                cell: info => (
                    <Flex alignItems="center" gap={1}>
                        <IconButton
                            bg="transparent"
                            onClick={() => {
                                handleDelete(info?.row?.original);
                            }}
                        >
                            <DeleteIcon color="red.400" boxSize={4} />
                        </IconButton>

                    </Flex>
                ),
            }),
        ],
        [data]
    );

    const table = useReactTable({
        data: data || [],
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <Th key={header.id} w="120px">
                                {header.isPlaceholder ? null : (
                                    <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                        }[header.column.getIsSorted()] ?? null}
                                    </Box>
                                )}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {isEmpty(table.getRowModel().rows) ? (
                    <Tr>
                        <Td textAlign="center" colSpan={5}>
                            Không có dữ liệu
                        </Td>
                    </Tr>
                ) : (
                    table.getRowModel().rows.map(row => (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                            ))}
                        </Tr>
                    ))
                )}
            </Tbody>
        </Table>
    );
};

export default CodeTable;
