import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getListMember = params => request.get(ROOT_API + '/api/v1/member/members', { params });
export const getProfileMember = params => request.get(ROOT_API + '/api/v1/member/profile', { params });
export const getDetailMember = id => request.get(ROOT_API + `/api/v1/member/detail/${id}`)
export const createCoinForUser = data => request.post(ROOT_API + '/api/v1/user/update-coin-for-admin', data);
export const createCoin = data => request.post(ROOT_API + '/api/v1/user/create-coin', data)
export const redeemCoin = data => request.post(ROOT_API + '/api/v1/user/redeem-coin', data)

//mutation
export const useCreateCoinForUserMutation = () => useMutation({ mutationFn: createCoinForUser });
export const useCreateCoinMutation = () => useMutation({ mutationFn: createCoin });
export const useRedeemCoinMutation = () => useMutation({ mutationFn: redeemCoin });
// Query
export const useQueryGetListMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['members', params], queryFn: () => getListMember(params), ...options });
export const useQueryGetProfileMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['memberProfile', params], queryFn: () => getProfileMember(params), ...options });
export const useQueryGetDetailMember = (id, options = {}) =>
  useQuery({ queryKey: ['memberDetail', id], queryFn: () => getDetailMember(id), ...options });
